import type { FC } from 'react';

export enum AllSteps {
  MSISDN_SCREEN_STEP = 'msisdn_screen_step',
  MSISDN_SUBMIT_STEP = 'msisdn_submit_step',
  SINCH_OTP_STEP = 'sinch_otp_step',
  FIREBASE_OTP_STEP = 'firebase_otp_step',
  INFO_STEP = 'info_step',
  FORM_STEP = 'form_step',
  PROMO_CODE_STEP = 'promo_code_step',
  COUNTRY_SCREEN_STEP = 'country_screen_step',
  PERMISSION_REQUEST_STEP = 'permission_requests_step',
  RATES_STEP = 'rates_step',
  TEST_CALL_STEP = 'test_call_step',
  CURRENCIES_SCREEN_STEP = 'currencies_screen_step',
  LOG_TO_APP_STEP = 'login_to_app_step',
  FLASH_CALL_STEP = 'flash_call_step',
  FLASH_CALL_PERMISSION_STEP = 'flash_call_permissions_step',
}

export type Steps = AllSteps;

type MasterSteps = {
  [key in Steps]: {
    ROUTE: ROUTES;
    MATCH_CHILDREN_ROUTES?: boolean;
  };
};

export enum ROUTES {
  PHONE_VERIFICATION = '/phone-verification',
  PHONE_INPUT = '/msisdn',
  OTP_VERIFICATION = '/phone-verification/otp',
  PHONE_VERIFICATION_OTP_SINCH = '/msisdn/otp-sinch',
  PHONE_VERIFICATION_OTP_FIREBASE = '/msisdn/otp-firebase',
  EMAIL_VERIFICATION = '/email-verification',
  INFO_PAGE = '/onboarding',
  AGENT_CODE = '/agent-code',
  IMPORT_CONTACTS = '/import-contacts',
  CURRENCY = '/currency',
  COUNTRY = '/country',
  SHOW_RATES = '/show-rates',
  TEST_CALL = '/test-call',
  LOGIN = '/login',
  TERMS_AND_CONDITION = '/tandc',
  PLAYGROUND = '/playground',
  FLASH_CALL = '/flash-call',
  FORCE_UPDATE = '/force-update',
  REGISTRATION_BLOCKED = '/registration-blocked',
}

export const MASTER_STEPS: MasterSteps = {
  [AllSteps.MSISDN_SCREEN_STEP]: {
    ROUTE: ROUTES.PHONE_VERIFICATION,
  },
  [AllSteps.MSISDN_SUBMIT_STEP]: {
    ROUTE: ROUTES.PHONE_INPUT,
  },
  [AllSteps.FLASH_CALL_PERMISSION_STEP]: {
    ROUTE: ROUTES.PHONE_INPUT,
  },
  [AllSteps.SINCH_OTP_STEP]: {
    ROUTE: ROUTES.PHONE_VERIFICATION_OTP_SINCH,
  },
  [AllSteps.FIREBASE_OTP_STEP]: {
    ROUTE: ROUTES.PHONE_VERIFICATION_OTP_FIREBASE,
  },
  [AllSteps.INFO_STEP]: {
    ROUTE: ROUTES.INFO_PAGE,
    MATCH_CHILDREN_ROUTES: true,
  },
  [AllSteps.FORM_STEP]: {
    ROUTE: ROUTES.EMAIL_VERIFICATION,
  },
  [AllSteps.PROMO_CODE_STEP]: {
    ROUTE: ROUTES.AGENT_CODE,
  },
  [AllSteps.PERMISSION_REQUEST_STEP]: {
    ROUTE: ROUTES.IMPORT_CONTACTS,
  },
  [AllSteps.CURRENCIES_SCREEN_STEP]: {
    ROUTE: ROUTES.CURRENCY,
  },
  [AllSteps.COUNTRY_SCREEN_STEP]: {
    ROUTE: ROUTES.COUNTRY,
  },
  [AllSteps.RATES_STEP]: {
    ROUTE: ROUTES.SHOW_RATES,
  },
  [AllSteps.TEST_CALL_STEP]: {
    ROUTE: ROUTES.TEST_CALL,
  },
  [AllSteps.LOG_TO_APP_STEP]: {
    ROUTE: ROUTES.LOGIN,
  },
  [AllSteps.FLASH_CALL_STEP]: {
    ROUTE: ROUTES.FLASH_CALL,
  },
};

export interface RouteI {
  path: string;
  component: FC;
}
