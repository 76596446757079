import i18n from 'i18n';
export interface ServerError {
  code: number;
  message: string;
}

/**
 * @function getError
 * @description This function takes an error code gotten from the API response
 * and returns the corresponding error message in the user's language.
 * @param {string} code this is the error code gotten from the API response
 * @return {*}  {string} The error message
 */
export const getError = (code: number | string): string => {
  /**
   * The error code 113 is handled specially as user would be sent to a
   * different page.
   */
  if (code === 113) return code.toString();

  /**
   * The path to the translation is constructed by prefixing the error code
   * with the path to the server error translations
   */
  const translation = `server_errors.${code}`;

  /**
   * If the translation exists, return it. Otherwise return the default
   * error message
   */
  return i18n.exists(translation) ? translation : 'server_errors.112';
};
