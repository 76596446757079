import { session } from '@talk360/utils/session';
import type { FunctionComponent, PropsWithChildren } from 'react';
import classnames from 'classnames';
import { SUPPORT_EMAIL } from '@talk360/constants';
interface Props {
  phoneNumber?: string;
  className?: string;
}

export const LinkContainer: FunctionComponent<PropsWithChildren<Props>> = ({
  children,
  phoneNumber,
  className,
}) => {
  const { os } = session.getItem('session_payload') as { os: 'android' | 'ios' };

  const sendEmail = () => {
    const { device } = window;
    if (device?.sendSupportEmail) {
      device.sendSupportEmail(SUPPORT_EMAIL);
    }
  };

  if (os === 'android') {
    return (
      <span className={classnames('underline', className)} onClick={sendEmail}>
        {children}
      </span>
    );
  }
  return (
    <a
      href={`mailto:${SUPPORT_EMAIL}?subject=${encodeURIComponent(
        'Having issue with Login/Signup',
      )}&body=${phoneNumber ? encodeURIComponent(`Phone number: +${phoneNumber}`) : ''}`}
      className={classnames('underline', className)}
    >
      {children}
    </a>
  );
};
