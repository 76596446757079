export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID;
export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID;
export const RATE_BASE_URL = 'https://platform.rbnbv.com/en/ringcredible/forced_currency/';
export const SEGMENT_ID = process.env.REACT_APP_SEGMENT;
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
export const ENV = process.env.NODE_ENV as 'production' | 'staging' | 'test' | 'development';
export const APP_BUILD = process.env.REACT_APP_BUILD;
export const APP_ENV = process.env.REACT_APP_ENV as
  | 'production'
  | 'staging'
  | 'test'
  | 'development';
export const SUPPORT_EMAIL = 'support@talk360.com';
